import React from "react";
import "@assets/styles/globalStyle.css";
import "./headerStyle.css";

const Header = () => {
  return (
    <div class="page-size">
      {/* <div class="background">&nbsp;</div> */}
      <header>
        {/* <!--//////////////////////////////////////////Heading txt//////////////////////////////////--> */}
        <div className="text-box-main">
          <h1 className="heading-primary">
            <span className="heading-primary-R">R</span>
            <span className="heading-primary-B">B</span>
            <span className="heading-primary-C">B</span>
          </h1>
        </div>
        <div className="textbox-sub">
          <h2>
            <span className="heading-primarySub">
              Electrical
              <span className="heading-secondrySub">contracting inc.</span>
            </span>
          </h2>
        </div>
      </header>
    </div>
  );
};
export default Header;
