import React from "react";
import logo from "@assets/logo/logo.jpg";
import "./navigationDesktopStyle.css";

const NavigationDesktop = () => {
  return (
    <nav className="main-navigation">
      <div class="logo-box">
        <img class="logo" src={logo} alt="logo" />
      </div>
      <ul className="navigation-list">
        <li class="navigation-item">
          <a href="#home" class="navigation-link">
            Home
          </a>
        </li>
        <li class="navigation-item">
          <a href="#" class="navigation-link">
            about
          </a>
        </li>
        <li class="navigation-item">
          <a href="about.html" class="navigation-link">
            Services
          </a>
        </li>
        <li class="navigation-item">
          <a href="#" class="navigation-link">
            gallery
          </a>
        </li>
        <li class="navigation-item">
          <a href="#" class="navigation-link">
            contact
          </a>
        </li>
      </ul>
    </nav>
  );
};
export default NavigationDesktop;
