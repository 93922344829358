import React from "react";
import "@assets/styles/globalStyle.css";
import {useMediaQuery} from "react-responsive";
import AboutSection from "./About/AboutSection.jsx";
import Services from "./Services/Services.jsx";
import CertificateSection from "./Certificate/CertificateSection";
import FooterSection from "./footer/FooterSection";
import NavigationDesktop from "./Navigation/NavigationDesktop.jsx";
import NavigationMobile from "./Navigation/NavigationMobile.jsx";
import Header from "./Header/Header.jsx";
import "@assets/styles/globalStyle.css";

const Home = () => {
  const isMobile = useMediaQuery({maxWidth: 1200});
  return (
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>

        <link
          href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..
             1000;1,6..12,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap"
          rel="stylesheet"
        ></link>
        <title>RBB Electrical</title>
      </head>
      <body>
        {isMobile ? (
          <>
            <NavigationMobile />
            <Header />
          </>
        ) : (
          <>
            <NavigationDesktop />
            <Header />
          </>
        )}

        <main>
          <AboutSection />
          <Services />
          <CertificateSection />
          <FooterSection />
        </main>
      </body>
    </html>
  );
};
export default Home;
