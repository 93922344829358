import React from "react";
import QRCode from "react-qr-code";

const QrCodeInstagram = () => {
  const InstagramUrl =
    "https://www.instagram.com/rbb.electrical.inc?igsh=OWZoajIzOTAxMjJs";
  return (
    <QRCode
      value={InstagramUrl}
      size={200}
      style={{paddingBottom: "0.2rem", paddingTop: "0.5rem"}}
    />
  );
};
export default QrCodeInstagram;
