import React from "react";
import logo from "@assets/logo/logo.jpg";
import "./navigationMobileStyle.css";
import "@assets/styles/globalStyle.css";
const NavigationMobile = () => {
  const navItems = [
    {name: "Home", link: "#home"},
    {name: "About", link: "#"},
    {name: "Services", link: "#"},
    {name: "Gallery", link: "#"},
    {name: "Contact", link: "#"},
  ];
  const navItemList = navItems.map((item, index) => (
    <li className="navigationMobile-item" key={index}>
      <a className="navigationMobile-link" href={item.link}>
        {item.name}
      </a>
    </li>
  ));
  return (
    <div className="navigationMobile">
      <input
        type="checkbox"
        className="navigationMobile-checkbox"
        id="navi-toggle"
      />
      <label htmlFor="navi-toggle" className="navigationMobile-button">
        <span class="menu__bar"></span>
        <span class="menu__bar"></span>
        <span class="menu__bar"></span>
      </label>

      <div className="navigationMobile-background">&nbsp;</div>
      <div className="logo-box">
        <img className="logo" src={logo} alt="logo" />
      </div>

      <nav className="navigationMobile-nav">
        <ul className="navigationMobile-list">
          {navItems.length > 0 ? navItemList : <li>No navigation items</li>}
        </ul>
      </nav>
    </div>
  );
};
export default NavigationMobile;
