import React from "react";
import "./certificateStyle.css";
import "@assets/styles/globalStyle.css";

const CertificateSection = () => {
  return (
    <section class="certificate-section">
      <h2 class="heading-secondry heading-secondry--certificate">Member of:</h2>
      <div class="certificate">
        <div class="card-a">
          <div class="card-a__pic">&nbsp;</div>
        </div>
        <div class="card-b">
          <div class="card-b__pic">&nbsp;</div>
        </div>
      </div>
    </section>
  );
};
export default CertificateSection;
