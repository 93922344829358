import React from "react";
import {useMediaQuery} from "react-responsive";
import logo from "@assets/logo/logo.jpg";
import linkedIn from "@assets/pic/footerIcon/linkedin.png";
import Instagram from "@assets/pic/footerIcon/instagram.png";
import youtubeIcon from "@assets/pic/footerIcon/youtube-logo.png";
import QRCodeInstagram from "../QRCodeInstagram";
import "./footerStyle.css";

const FooterSection = () => {
  const isDesktop = useMediaQuery({query: "(min-width:1200px)"});
  return (
    <footer class="footer-feature">
      {/* <!--flex container--> */}
      <div class="footer-container">
        {/* <!--//////////////////////////////////////////////////First column from left////////////////////////////////////////////--> */}
        <div class="footer-flex">
          <img class="footer-about--logo" src={logo} alt="company logo" />
          <div class="footer---paragraph">
            <p>
              RBB Electrical Contractin INC is a leading electrical company that
              specializes in providing services to residential,and commercial
              clients.
            </p>
          </div>
          {/* <!-- close footer-feature__firstColumn" --> */}
        </div>
        <div class="footer-flex footer-ContactColumn">
          <p class="footer-ContactColumn--contactUs">Contact us:</p>

          {/* <!--classes are for title in every row, such as Address, and for span for example address details--> */}
          <p class="footer-ContactColumn--titleRow">
            Address :
            <span class="footer-ContactColumn--p"> Toronto , ON, Canada</span>
          </p>
          <p class="footer-ContactColumn--titleRow">
            phone number :
            <span class="footer-ContactColumn--p"> (647) 482 - 6000</span>
          </p>
          <p class="footer-ContactColumn--titleRow">
            Email :
            <span class="footer-ContactColumn--p"> rbb.elec@gmail.com</span>
          </p>
          {/* <!--for social media link and icon--> */}
          <div class="footer-ContactColumn--link">
            <a href="https://www.linkedin.com/in/ramin-ramazani-151230a9/">
              <img class="footer-icon" src={linkedIn} alt="LinkedIn icon" />
            </a>
            <a href="https://www.instagram.com/rbb.electrical.inc?igsh=OWZoajIzOTAxMjJs">
              <img class="footer-icon" src={Instagram} alt="Instagram icon" />
            </a>
            <a href="https://www.youtube.com/@rbb-elecctrical">
              " <img class="footer-icon" src={youtubeIcon} alt="youtube icon" />
            </a>
          </div>
        </div>

        {isDesktop ? (
          <>
            <div class="footer-flex footer-QrCode">
              <p class="footer-QrCode--titleRow">
                Please scan the QR code to link to our Instagram
              </p>
              <QRCodeInstagram />
            </div>
          </>
        ) : null}
      </div>
    </footer>
  );
};
export default FooterSection;
